<template>
  <v-form ref="formElem">
    <v-card>
      <v-card-text>
        <h2>Create Refund</h2>
        <v-select
          v-model="form.type"
          label="type"
          outlined
          item-text="name"
          item-value="value"
          :items="typeOptions"
          :rules="[(v) => !!v || 'Item is required']"
          class="mt-5"
        />

        <v-text-field
          v-model="form.total"
          type="number"
          min="0"
          max="99999999"
          label="Total"
          outlined
          :rules="[integerValidator]"
          class="mt-5"
        />
        <v-switch
          v-model="form.to_balance"
          color="primary"
          :label="form.to_balance ? 'Refund to balance' : 'Refund by offline method'"
        ></v-switch>
        <p>Note: Refund by offline method likes refund by cash, bank transfer, check etc... </p>

        <v-text-field
          v-model="form.remarks"
          label="Remarks"
          outlined
          :rules="[]"
          class="mt-5"
        />

        <v-text-field
          v-model="form.reference_number"
          label="Reference Number"
          outlined
          :rules="[]"
          class="mt-5"
        />
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { integerValidator, required } from '@core/utils/validation'
import { } from '@mdi/js'
import { ref } from '@vue/composition-api'

import { SearchUser } from '@/components'

export default {
  components: {
    SearchUser,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    // Get value from resource prop, use initValue if not exist
    const field = (name, initValue) => ({
      [name]: initValue,
    })

    const initialForm = {
      ...field('type', null),
      ...field('total', 0),
      ...field('to_balance', true),
      ...field('remarks', ''),
      ...field('reference_number', ''),
    }

    const form = ref({
      ...initialForm,
    })
    const formElem = ref(null)

    const typeOptions = ref([
      {
        name: 'Active User',
        value: 'active_user',
      },
      {
        name: 'Used Credit',
        value: 'used_credit',
      },
    ])

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    return {
      form,
      formElem,
      validate,
      required,
      integerValidator,
      typeOptions,
    }
  },
}
</script>
