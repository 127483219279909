var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "formElem"
  }, [_c('v-card', [_c('v-card-text', [_c('h2', [_vm._v("Create payment")]), _c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "type": "number",
      "min": "0",
      "max": "99999999",
      "label": "Total",
      "outlined": "",
      "rules": [_vm.integerValidator]
    },
    model: {
      value: _vm.form.total,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "total", $$v);
      },
      expression: "form.total"
    }
  }), _c('v-select', {
    staticClass: "mt-5",
    attrs: {
      "label": "Type",
      "outlined": "",
      "item-text": "name",
      "item-value": "value",
      "items": _vm.typeOptions,
      "rules": [function (v) {
        return !!v || 'Item is required';
      }]
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }), _c('v-select', {
    staticClass: "mt-5",
    attrs: {
      "label": "To Status",
      "outlined": "",
      "item-text": "name",
      "item-value": "value",
      "items": _vm.statusOptions,
      "rules": [function (v) {
        return !!v || 'Item is required';
      }]
    },
    model: {
      value: _vm.form.to_status,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "to_status", $$v);
      },
      expression: "form.to_status"
    }
  }), _c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "Remarks",
      "outlined": "",
      "rules": []
    },
    model: {
      value: _vm.form.remarks,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "remarks", $$v);
      },
      expression: "form.remarks"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Reference Number",
      "outlined": "",
      "rules": []
    },
    model: {
      value: _vm.form.reference_number,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "reference_number", $$v);
      },
      expression: "form.reference_number"
    }
  })], 1), _vm._v(" "), _c('v-card-actions', {
    staticClass: "justify-center"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.validate
    }
  }, [_vm._v(" Submit ")])], 1), _vm._l(_vm.errors, function (msg) {
    return _c('v-alert', {
      key: msg,
      attrs: {
        "color": "warning"
      }
    }, [_vm._v(" " + _vm._s(msg) + " ")]);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }