import axios from '@axios'

const fetchInvoices = params => axios.get('/admin/invoices', { params })
// const fetchInvoice = id => axios.get(`/admin/invoices/${id}`)
// const createInvoice = data => axios.post(`/admin/invoices`, data)
const createInvoiceRefund = (id, data) => axios.post(`/admin/invoices/${id}/refunds`, data)
const createInvoicePayment = (id, data) => axios.post(`/admin/invoices/${id}/payments`, data)
// const destroyInvoice = id => axios.delete(`/admin/invoices/${id}`)

export {
  fetchInvoices,
  createInvoiceRefund,
  createInvoicePayment,
  //  fetchInvoice, createInvoice, updateInvoice, destroyInvoice
}
