import { useTableOptions } from '@/composables'
import { fetchInvoices } from '@api/invoice/invoice'
import { ref, watch } from '@vue/composition-api'

const cached = []

export default function useList() {
  const tableList = ref([])

  // Table Handlers
  const tableColumns = [
    { text: 'ID', value: 'id' },
    { text: 'Distributor', value: 'distributor.nicename' },
    { text: 'Date', value: 'issue_key' },
    { text: 'Issued?', value: 'issued' },
    { text: 'Total', value: 'total' },
    { text: 'Refund Total', value: 'refund_total' },
    { text: 'Payment Total', value: 'payment_total' },
    { text: 'status', value: 'status' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
    },
  )
  const searchQuery = ref(cached[1] || '')
  const tableTotal = ref(0)
  const loading = ref(false)
  const tableSelectedData = ref([])

  const loadInvoices = () =>
    fetchInvoices(
      useTableOptions(options.value, {
        distributor_id: searchQuery.value,

        // email: emailQuery.value,
      }),
    )
      .then(({ data }) => {
        const { records, pagination } = data.data
        tableList.value = records
        tableTotal.value = pagination.total
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })

  const cachable = [options, searchQuery]

  const cache = () => {
    cachable.forEach((variable, index) => {
      cached[index] = variable.value
    })
  }

  watch(cachable, () => {
    const newPage = options.value.page

    const hasDiff = cachable.find((variable, index) => index > 0 && cached[index] !== variable.value)

    if (newPage >= 2 && hasDiff) {
      options.value.page = 1
    }

    loading.value = true
    tableSelectedData.value = []
    loadInvoices()
    cache()
  })

  return {
    tableColumns,
    searchQuery,
    options,
    tableList,
    tableTotal,
    loading,
    tableSelectedData,
    loadInvoices,
  }
}
