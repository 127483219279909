<template>
  <v-form ref="formElem">
    <v-card>
      <v-card-text>
        <h2>Create payment</h2>
        <v-text-field v-model="form.total" type="number" min=0 max=99999999 label="Total" outlined
          :rules="[integerValidator]" class="mt-5" />

        <v-select v-model="form.type" label="Type" outlined item-text="name" item-value="value"
          :items="typeOptions" :rules="[(v) => !!v || 'Item is required']" class="mt-5" />

        <v-select v-model="form.to_status" label="To Status" outlined item-text="name"
          item-value="value" :items="statusOptions" :rules="[(v) => !!v || 'Item is required']"
          class="mt-5" />


        <v-text-field v-model="form.remarks" label="Remarks" outlined :rules="[]" class="mt-5" />

        <v-text-field v-model="form.reference_number" label="Reference Number" outlined :rules="[]" />
          </v-card-text> <v-card-actions class="justify-center">
          <v-btn color="primary" :loading="loading" @click="validate">
            Submit
          </v-btn>
          </v-card-actions>

          <v-alert v-for="msg in errors" :key="msg" color="warning">
            {{ msg }}
          </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { required, integerValidator } from '@core/utils/validation'
import {} from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import { t } from '@/plugins/i18n'
import moment from 'moment'

import usePaymentForm from './usePaymentForm'

export default {
  components: {},

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const { industries, loadIndustries, countries, loadCountries } = usePaymentForm()
    // Get value from resource prop, use initValue if not exist
    const field = (name, initValue) => ({
      [name]: initValue,
    })
    const typeOptions = ref([
      {
        name: 'Bank transfer',
        value: 'bank_transfer',
      },
    ])

    const statusOptions = ref([
      {
        name: 'Partially paid',
        value: 'partially_paid',
      },
      {
        name: 'Paid',
        value: 'paid',
      },
    ])

    const initialForm = {
      ...field('total', 0),
      ...field('type', null),
      ...field('to_status', null),
      ...field('remarks', ''),
      ...field('reference_number', ''),
    }

    const form = ref({
      ...initialForm,
    })
    // watch(form.financial_start_date.value, (newValue, oldValue) => {
    //   console.log('watch search', newValue, oldValue)
    // })
    const formElem = ref(null)

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    return {
      form,
      formElem,
      validate,
      required,
      integerValidator,

      industries,
      loadIndustries,
      loadCountries,
      countries,
      props,
      t,
      typeOptions,
      statusOptions,
    }
  },
}
</script>
